<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="sale" style="width:100vw">
  <div id="a59c04fb">
    管理信息
  </div>
  <div id="fdb624ae">
    <div id="a1156eb7">
      <div id="a8ce4f4f">
        当季剩余任务金额
      </div>
      <div id="c7dc6d31">
        5000
      </div>
    </div>
    <div id="a4786502">
      <div id="a64cdb46">
        当季已完成任务金额
      </div>
      <div id="af6c7b2f">
        10000
      </div>
    </div>
    <div id="ad6488d4">
      <div id="aaf23c4e">
        当月新增客户数
      </div>
      <div id="ab891516">
        2
      </div>
    </div>
    <div id="a41c22ba">
      <div id="a09462d4">
        当月启动项目数
      </div>
      <div id="a33b7f8b">
        3
      </div>
    </div>
    <div id="a292d236">
      <div id="a06ff0fa">
        当月交付项目数
      </div>
      <div id="ada80074">
        6
      </div>
    </div>
    <div id="a6be2aad">
      <div id="a34c84a2">
        跟进中用户数
      </div>
      <div id="f02b2fec">
        4
      </div>
    </div>
  </div>
  <div id="bf302a2f">
    客户列表
  </div>
  <el-button id="a3263573" @click="clicka3263573()">
    +新增客户
  </el-button>
  <div id="ab98508a">
    <dal-comp-customer id="cmpab98508a">
    </dal-comp-customer>
  </div>
  <el-pagination id="af411d49">
  </el-pagination>
  <div id="ae6df190">
    进行中项目
  </div>
  <div id="aecceb63">
    <dal-comp-go id="cmpaecceb63">
    </dal-comp-go>
  </div>
  <el-pagination id="fe808676">
  </el-pagination>
  <div id="dlgfa9ecb8bc" v-show="vis_a9ecb8bc" @click.self="vis_a9ecb8bc=false">
    <div id="a9ecb8bc">
      <div id="a955c62f">
        新增客户
      </div>
      <div id="a8cae05c">
        <div id="ed68378f">
          客户列表
        </div>
        <el-input id="ed0cf35e" v-model="inp_ed0cf35e">
        </el-input>
      </div>
      <el-table id="a448e8c1" :data="tbd_a448e8c1" stripe="" border="">
        <el-table-column prop="d8222b35" label="全选">
        </el-table-column>
        <el-table-column prop="a94d24f5" label="客户姓名">
        </el-table-column>
        <el-table-column prop="a561d9a2" label="电子邮箱">
        </el-table-column>
        <el-table-column prop="a6bb7973" label="登记时间">
        </el-table-column>
        <el-table-column prop="aab94f71" label="手机号">
        </el-table-column>
      </el-table>
      <div id="ae84541c">
        <el-button id="af3fd22b" @click="clickaf3fd22b()">
          确认
        </el-button>
        <el-button id="bbd26441" @click="clickbbd26441()">
          取消
        </el-button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_comp_customer from '../components/dal-comp-customer'
import dal_comp_go from '../components/dal-comp-go'
export default{
  components:{
    'dal-comp-customer':dal_comp_customer,
    'dal-comp-go':dal_comp_go
  },
  data(){
    return{
    vis_a9ecb8bc:false,
    inp_ed0cf35e:'',
    tbd_a448e8c1:[]
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    async clicka3263573(){
      this.vis_a9ecb8bc = true
    },
    async clickaf3fd22b(){
    },
    async clickbbd26441(){
    },
    //在此注释下方添加自定义函数或事件
    async clickaef233a7(){
    },
    async clicka9bc7721(){
    }
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#a59c04fb{
  padding-top:100px;
}
#a59c04fb{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#fdb624ae{
  width:1326px;
  position:relative;
  margin-top:30px;
  padding-left:163px;
}
#a1156eb7{
  width:203px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#a1156eb7{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a8ce4f4f{
  padding-top:20px;
}
#a8ce4f4f{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#c7dc6d31{
  margin-top:18px;
  padding-bottom:21px;
}
#c7dc6d31{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#a4786502{
  width:203px;
  position:relative;
  margin-left:22px;
  display:inline-block;
  vertical-align: middle;
}
#a4786502{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a64cdb46{
  padding-top:20px;
}
#a64cdb46{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#af6c7b2f{
  margin-top:18px;
  padding-bottom:21px;
}
#af6c7b2f{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#ad6488d4{
  width:203px;
  position:relative;
  margin-left:21px;
  display:inline-block;
  vertical-align: middle;
}
#ad6488d4{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#aaf23c4e{
  padding-top:20px;
}
#aaf23c4e{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#ab891516{
  margin-top:18px;
  padding-bottom:21px;
}
#ab891516{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#a41c22ba{
  width:203px;
  position:relative;
  margin-left:22px;
  display:inline-block;
  vertical-align: middle;
}
#a41c22ba{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a09462d4{
  padding-top:20px;
}
#a09462d4{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#a33b7f8b{
  margin-top:18px;
  padding-bottom:21px;
}
#a33b7f8b{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#a292d236{
  width:203px;
  position:relative;
  margin-left:21px;
  display:inline-block;
  vertical-align: middle;
}
#a292d236{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a06ff0fa{
  padding-top:20px;
}
#a06ff0fa{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#ada80074{
  margin-top:18px;
  padding-bottom:21px;
}
#ada80074{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#a6be2aad{
  width:203px;
  position:relative;
  margin-left:22px;
  display:inline-block;
  vertical-align: middle;
}
#a6be2aad{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a34c84a2{
  padding-top:20px;
}
#a34c84a2{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#f02b2fec{
  margin-top:18px;
  padding-bottom:21px;
}
#f02b2fec{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#bf302a2f{
  margin-top:60px;
}
#bf302a2f{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a3263573{
  width:120px;
  margin-top:30px;
  margin-left:1365px;
}
#a3263573{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#a3263573{
  color:#fff;
  font-size:13px;
  font-family:PingFang SC;
  border:transparent;
}
#ab98508a{
  width:1322px;
  margin-top:30px;
  padding-left:163px;
}
#af411d49{
  width:302px;
  margin-top:53px;
  padding-left:673px;
}
#ae6df190{
  margin-top:60px;
}
#ae6df190{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#aecceb63{
  width:1323px;
  margin-top:30px;
  padding-left:163px;
}
#fe808676{
  width:302px;
  margin-top:60px;
  padding-left:673px;
  padding-bottom:69px;
}
#dlgfa9ecb8bc{
  position:fixed;
  z-index: 100;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background:rgba(0,0,0,0.5);
}
#a9ecb8bc{
  position:absolute;
  top:666px;
  left:610px;
  width:700px;
  height:561px;
  border-radius:10px 10px 10px 10px;
  background:#fff;
}
#a955c62f{
  padding-top:30px;
}
#a955c62f{
  text-align:left;
  color:#333;
  font-size:24px;
  font-family:PingFang SC;
}
#a8cae05c{
  width:616px;
  position:relative;
  margin-top:20px;
  padding-left:40px;
  display:inline-block;
  vertical-align: middle;
}
#ed68378f{
  padding-top:2px;
  padding-bottom:1px;
  display:inline-block;
  vertical-align: middle;
}
#ed68378f{
  text-align:center;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#a8cae05c .el-input{
  width:278px;
  margin-left:286px;
  display:inline-block;
  vertical-align: middle;
}
#a8cae05c .el-input .border{
  border:1px solid #979797;
  border-radius:30px;
}
#a448e8c1{
  width:613px;
  margin-top:20px;
  margin-left:40px;
}
#a448e8c1{
  display:inline-block;
  vertical-align: middle;
}
#ae84541c{
  width:404px;
  position:relative;
  margin-top:40px;
  padding-left:148px;
  padding-bottom:30px;
}
#af3fd22b{
  width:142px;
  display:inline-block;
  vertical-align: middle;
}
#af3fd22b{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#af3fd22b{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
#bbd26441{
  width:142px;
  margin-left:120px;
  display:inline-block;
  vertical-align: middle;
}
#bbd26441{
  background-color:rgb(153,153,153,1);
  border-radius:0px 0px 0px 0px;
}
#bbd26441{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
/*在此注释下方添加自定义样式*/
</style>
